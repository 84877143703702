<template>
  <div>
     <!-- Container-fluid starts-->
      <div class="container-fluid">
        <div class="row">
<br>
          <div class="col-sm-12">
<br>
            <div class="card">
              <div class="card-header">
                <h5>Registro de datos</h5>
              </div>
              <div class="card-body">
                 <form-wizard shape="square" color="#4466f2" title="" subtitle="">
                    <tab-content title="Identificación" icon="fa fa-user">
                      <div class="setup-content" id="step-1">
                      <div class="col-xs-12">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Sucursal</label>
                            <b-form-select v-model="numberSelectedEdgeInput" class="btn-pill" :options="options1"></b-form-select>
     
                          </div>
                          <div class="form-group">
                            <label class="control-label">Departamento</label>
                            <b-form-select v-model="numberSelectedEdgeInput" class="btn-pill" :options="options2"></b-form-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    </tab-content>
                    <tab-content title="BSC" icon="fa fa-cog">
                       <div class="setup-content" id="step-2">
                        <div class="col-xs-12">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="control-label">Capa</label>
                              <b-form-select v-model="numberSelectedEdgeInput" class="btn-pill" :options="options6"></b-form-select>
                            </div>
                            <div class="form-group">
                              <label class="control-label">Objetivo</label>
                              <b-form-select v-model="numberSelectedEdgeInput" class="btn-pill" :options="options4"></b-form-select>
                            </div>

                            <div class="form-group">
                              <label class="control-label">Iniciativa</label>
                              <input class="form-control" type="text" placeholder=" " required="required">
                            </div>

                            <div class="form-group">
                              <label class="control-label">Indicador</label>
                              <input class="form-control" type="text" placeholder=" " required="required">
                            </div>

                            <div class="form-group">
                              <label class="control-label">Periodicidad</label>
                              <b-form-select v-model="numberSelectedEdgeInput" class="btn-pill" :options="options5"></b-form-select>
                            </div>

                            <div class="form-group">
                              <label class="control-label">Resultado</label>
                              <input class="form-control" type="text" placeholder=" " required="required">
                            </div>

                          </div>
                        </div>
                      </div>
                    </tab-content>
                    <tab-content title="Validación" icon="fa fa-check">
                      <div class="setup-content" id="step-3">
                        <div class="col-xs-12">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="control-label">Fecha</label>
                              <input class="form-control" type="date" required="required">
                            </div>
                            <div class="form-group">
                              <label class="control-label">Archivo</label>
                              <b-form-file class="form-control" plain></b-form-file>
                            </div>

                            <div class="form-group">
                              <label>Notas y comentarios</label>
                                <b-form-textarea rows="3"></b-form-textarea>
                            </div>

                          </div>
                        </div>
                      </div>
                    </tab-content>
                </form-wizard>
              </div>
            </div>
          </div>
 
        </div>
      </div>
      <!-- Container-fluid Ends-->
  </div>
</template> 
<script>
  import Multiselect from 'vue-multiselect'

export default {
  data(){
    return{
      singleValue:"",

      options1: [
        {value: 1, text: 'Principal'},
        {value: 2, text: 'Gran Caracas'},
        {value: 3, text: 'Anzoátegui'},
        {value: 4, text: 'Aragua'},
        {value: 5, text: 'Bolívar'},
        {value: 6, text: 'Carabobo'},
        {value: 7, text: 'Falcón'},
        {value: 8, text: 'Guárico'},
        {value: 9, text: 'Lara'},
        {value: 10, text: 'Mérida'},
        {value: 11, text: 'Monagas'},
        {value: 12, text: 'Nueva Esparta'},
        {value: 13, text: 'Portuguesa'},
        {value: 14, text: 'Táchira'},
        {value: 15, text: 'Trujillo'},
        {value: 16, text: 'Zulia'}

      ],

      options2: [
        {value: 1, text: 'Banca comercial'},
        {value: 1, text:'Banca de inversión'},
        {value: 1, text: 'Tesorería'},
        {value: 1, text: 'Tecnología'},
        {value: 1, text: 'Riesgo y cumplimiento'},
        {value: 1, text:'Gestión humana'},
        {value: 1, text: 'Mercadeo y negocios'}

      ],

      
      options3: [
        {value: 1, text: 'Gerente'},
        {value: 2, text:'Líder'},
        {value: 3, text: 'Coordinador'},
        {value: 4, text: 'Especialista'},
        {value: 5, text: 'Analista'},
        {value: 6, text: 'Consultor'}
      ],

      options4: [
        {value: 1, text: ' '},
        {value: 2, text: '-  Financiera  -'},
        {value: 3, text: 'Valor económico al accionista'},
        {value: 4, text: 'Mejorar eficiencia operativa'},
        {value: 5, text: 'Incrementar la rentabilidad'},
        {value: 6, text: 'Aumento de la participación de mercado'},
        {value: 7, text: ' '},
        {value: 8, text: '-  Clientes  -'},
        {value: 9, text: 'Satisfacción del cliente'},
        {value: 10, text: 'Retención del cliente'},
        {value: 11, text: ' '},
        {value: 12, text: '-  Procesos  -'},
        {value: 13, text: 'Mejorar tiempos de respuesta'},
        {value: 14, text: 'Reducción de tasa de errores'},
        {value: 15, text: ' '},
        {value: 16, text: '-  Aprendizaje  -'},
        {value: 17, text: 'Desarrollo de talentos'},
        {value: 18, text: 'Impulso de automatización tecnológica'},
        {value: 19, text: ' '},
        {value: 20, text: '-  Eficiencia  -'},
        {value: 21, text: 'Optimización de procesos'}

      ],

      options5: [
        {value: 1, text: 'Anual'},
        {value: 2, text: 'Semestral'},
        {value: 3, text: 'Trimestra'},
        {value: 4, text: 'Mensual'},
        {value: 5, text: 'Semanal'},
        {value: 6, text: 'Diario'}
      ],

      options6: [
        {value: 1, text: 'Financiera'},
        {value: 2, text: 'Clientes'},
        {value: 3, text: 'Procesos'},
        {value: 4, text: 'Aprendizaje'},
        {value: 5, text: 'Eficiencia'}
      ],
     
    }
  },
  components: {
    Multiselect
  },
   methods: {
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    getImgUrl(path) {
      return require("../../assets/images/" + path);
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
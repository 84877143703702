<template>
  <!-- Right sidebar Start-->
  <div class="right-sidebar" :class="{ show:rightsidebar_toggle_var }" id="right_side_bar">
    <div>
      <div class="container p-0">
        <div class="modal-header p-l-20 p-r-20">
          <div class="col-sm-8 p-0">
            <h6 class="modal-title font-weight-bold">FRIEND LIST</h6>
          </div>
          <div class="col-sm-4 text-right p-0">
            <feather class="mr-2" type="settings"></feather>
          </div>
        </div>
      </div>
      <div class="friend-list-search mt-0">
        <input
          v-model="search"
          v-on:keyup="setSerchUsers"
          class="form-control"
          type="text"
          placeholder="search"
        />
        <i class="fa fa-search"></i>
      </div>
    </div>
  </div>
  <!-- Right sidebar Ends-->
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "rightsidebar",
  props: ["rightsidebar_toggle_var", "msg"],
  data() {
    return {
      search: ""
    };
  },
  computed: {
    ...mapState({
      users: state => state.chat.users.filter(function (user) {
          if (user.id != 0)
              return user;
      }),
      activeusers: state =>
      state.chat.users.filter(function(user) {
        if (user.active == "active" && user.id != 0) return user;
      }),
      serchUser: state => state.chat.serchUser
       }),
   
  },
  methods: {
    getImgUrl(path) {
            return require('../assets/images/'+path)
        },
    setSerchUsers: function() {
      if (this.search != "")
        this.$store.dispatch("chat/setSerchUsers", this.search);
    }
  }
};
</script>

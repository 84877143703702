<template>
	<div>
		<!-- Container-fluid starts-->
		<div class="container-fluid">
			<div class="row">
<br>
				<div class="col-md-12">
<br>
					<div class="card">
						<div class="card-header">
							<h5 class="card-title">Registros de datos</h5>
						</div>
						<div class="card-body">
							<div class="datatable-vue m-0">

							<div class="row filter-smart">
								<div class="col-sm-2">
									<input class="form-control" v-model="filters.name.value" placeholder="Usuario"/>
								</div>
								<div class="col-sm-2">
									<input class="form-control" v-model="filters.position.value" placeholder="Fecha" />
								</div>
								<div class="col-sm-2">
									<input class="form-control" v-model="filters.office.value" placeholder="Sucursal" />
								</div>
								<div class="col-sm-2">
									<input class="form-control" v-model="filters.age.value" placeholder="Departamento" />
								</div>
								<div class="col-sm-2">
									<input class="form-control" v-model="filters.startdate.value" placeholder="Cargo" />
								</div>
								<div class="col-sm-2">
									<input class="form-control" v-model="filters.salary.value" placeholder="Capa" />
								</div>
							</div>


							<div class="row filter-smart">

								<div class="col-sm-2">
									<input class="form-control" v-model="filters.objetivo.value" placeholder="Objetivo" />
								</div>
								<div class="col-sm-2">
									<input class="form-control" v-model="filters.iniciativa.value" placeholder="Iniciativa" />
								</div>

								<div class="col-sm-2">
									<input class="form-control" v-model="filters.iniciativa.value" placeholder="Indicador" />
								</div>

								<div class="col-sm-2">
									<input class="form-control" v-model="filters.periodicidad.value" placeholder="Periodicidad" />
								</div>
								<div class="col-sm-2">
									<input class="form-control" v-model="filters.resultado.value" placeholder="Resultado" />
								</div>
	
							</div>
							<div class="table-responsive vue-smart">

							<v-table
							:data="users.data" class="table"
							:currentPage.sync="filter.currentPage"
							:pageSize="10"
							@totalPagesChanged="filter.totalPages = $event"
							:filters="filters"
							>

							<thead slot="head">
								<v-th sortKey="name">Usuario</v-th>
								<v-th sortKey="position">Fecha</v-th>
								<v-th sortKey="office">Sucursal</v-th>
								<v-th sortKey="age">Departamento</v-th>
								<v-th sortKey="startdate">Cargo</v-th>
								<v-th sortKey="salary">Capa</v-th>
								<v-th sortKey="objetivo">Objetivo</v-th>
								<v-th sortKey="iniciativa">Iniciativa</v-th>
								<v-th sortKey="iniciativa">Indicador</v-th>
								<v-th sortKey="periodicidad">Periodicidad</v-th>
								<v-th sortKey="peso">Peso</v-th>
								<v-th sortKey="archivo">Resultado</v-th>
								<v-th sortKey="notas">Notas</v-th>
								<v-th sortKey="archivo">Archivo</v-th>
							</thead>
							<tbody slot="body" slot-scope="{displayData}">
								<tr v-for="row in displayData" :key="row.id">
									<td>{{ row.name }}</td>
									<td>{{ row.position }}</td>
									<td>{{ row.office }}</td>
									<td>{{ row.age }}</td>
									<td>{{ row.startdate }}</td>
									<td>{{ row.salary }}</td>
									<td>{{ row.objetivo }}</td>
									<td>{{ row.iniciativa }}</td>
									<td>{{ row.indicador }}</td>
									<td>{{ row.periodicidad }}</td>
									<td>{{ row.peso }}</td>
									<td>{{ row.resultado }}</td>
									<td>{{ row.notas }}</td>
									<td>{{ row.archivo }}</td>
								</tr>
							</tbody>
						</v-table>
						</div>

						<smart-pagination
						:currentPage.sync="filter.currentPage"
						:totalPages="filter.totalPages"
						/>
					</div>
						</div>
				</div>
			</div>


	</div>


</div>
    </div>
<!-- Container-fluid Ends-->
</template>

<script>
import users from '../../../data/users'

export default {
	data(){
		return{
			users,
			
			basic:{
				currentPage: 1,
				totalPages: 0,
			},

			filter:{
				currentPage: 1,
				totalPages: 0,
			},

			agefilter:{
				currentPage: 1,
				totalPages: 0,
			},

			selectrow:{
				currentPage: 1,
				totalPages: 0,
			},

			selectedRows: [],

			filters: {
				name: { value: '', keys: ['name'] },
				position: { value: '', keys: ['position'] },
				office: { value: '', keys: ['office'] },
				age: { value: '', keys: ['age'] },
				startdate: { value: '', keys: ['startdate'] },
				salary: { value: '', keys: ['salary'] },
				objetivo: { value: '', keys: ['objetivo'] },
				iniciativa: { value: '', keys: ['iniciativa'] },
				indicador: { value: '', keys: ['indicador'] },
				periodicidad: { value: '', keys: ['periodicidad'] },
				peso: { value: '', keys: ['peso'] },
				resultado: { value: '', keys: ['resultado'] },
				notas: { value: '', keys: ['notas'] },
				archivo: { value: '', keys: ['archivo'] },
			},

			agefilters:{
				age: { value: { min: 19, max: 22 }, custom: this.ageFilter }
			}
		}
	},
	methods:{
		addMin:function(){
			if(this.agefilters.age.value.min<this.agefilters.age.value.max)
			{
				this.agefilters.age.value.min++
			}
		},
		subMin:function(){
			if(this.agefilters.age.value.min>0)
			{
				this.agefilters.age.value.min--
			}
		},
		addMax:function(){
			if(this.agefilters.age.value.max<99)
			{
				this.agefilters.age.value.max++
			}
		},
		subMax:function(){
			if(this.agefilters.age.value.max>this.agefilters.age.value.min)
			{
				this.agefilters.age.value.max--
			}
		},
		ageFilter (filterValue, row) {
			return row.age >= filterValue.min && row.age <= filterValue.max
		}
	}
}
</script>

<template>

  <div class="page-wrapper">
    <div class="container-fluid p-0">
      <div class="authentication-main">
        <div class="row">
          <div class="col-md-12">
            <div class="auth-innerright">
              <div class="authentication-box">
                <div class="text-center"><img alt="" src="../assets/images/endless-logo5.png"></div>
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="text-center">
                      <h4>Inicio de sesión</h4>
                      <h6>Ingrese su usuario y contraseña </h6>
                    </div>
                    <form class="theme-form">
                      <div class="form-group">
                        <label class="col-form-label pt-0">Usuario</label>
                        <input v-model="email" class="form-control" type="email" >
                      </div>
                      <div class="form-group">
                        <label class="col-form-label">Password</label>
                        <input v-model="password" :type="type" class="form-control" name="login[password]" >
                        <div class="show-hide"><span class="show" @click="showPassword"> </span></div>
                      </div>
                      <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox">
                        <label for="checkbox1">Recordar </label>
                      </div>
                      <div class="form-group form-row mt-3 mb-0">
                        <button class="btn btn-dark btn-block" type="button" @click="doLogin">Ingresar</button>
                      </div>
                      <div class="form-group form-row mt-3 mb-0" hidden>
                        <button class="btn btn-secondary btn-block" type="button">Login With Auth0</button>
                      </div>
                      <div class="login-divider" hidden></div>
                      <div class="social mt-3" hidden>
                        <div class="form-group btn-showcase d-flex">
                          <button class="btn social-btn btn-fb d-inline-block"><i class="fa fa-facebook"></i></button>
                          <button class="btn social-btn btn-twitter d-inline-block"><i class="fa fa-google"></i></button>
                          <button class="btn social-btn btn-google d-inline-block"><i class="fa fa-twitter"></i></button>
                          <button class="btn social-btn btn-github d-inline-block"><i class="fa fa-github"></i></button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
  
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';


export default {
  data() {
    return {
      type: 'password',
      email: "",
      password: "",
    };
  },

  methods: {
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    doLogin() {

    const email = this.email;
    const password = this.password;
    const auth = getAuth(); // Obtén la instancia de autenticación


     signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        localStorage.setItem('user', email);
        this.$router.push('/');
      })
      .catch(error => {
        this.$toasted.show('Oops... Datos incorrectos', {
          theme: 'bubble',
          position: "bottom-right",
          type: 'error',
          duration: 2000
          });
        });
    },
  },
};
</script>
